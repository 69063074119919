.footer {
    padding: .5rem;
    text-align: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    color:#707070;
    font-size:12px;
    font-family: "roboto",sans-serif;
}
.footerRight{
	float: right;
    color:#707070;
}
@media screen and (max-width: 1248px){
    .footer{
        font-size: 11px;
    }
}